.Testimons{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    gap: 1rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t :nth-child(1){
    color: #f48915;
    font-weight: bold;
}
.left-t :nth-child(2),.left-t :nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t :nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t >img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t >:nth-child(1){
    border: 2px solid #f48915;
    background-color: transparent;
    width: 17rem;
    height: 20rem;
    position: absolute;
    right: 9rem;
    top: 0.9rem;
}
.right-t >:nth-child(2){
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    width: 17rem;
    height: 19rem;
    position: absolute;
    /* right: 9rem; */
    top: 4rem;
    right: 7rem;
}
.arrows{
    display: flex;
    gap:1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:768px){
    .Testimons{
        flex-direction: column;
    }
    .left-t>:nth-child(3){
        font-size: 180%;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .right-t>div{
        display: none;
        position: relative;
    }
    .right-t>img{
        position: relative;
        left: 0;
        top: 0;
        width: 70%;
        height: 50%;
    }.right-t>:last-child{
        display: flex;
        left: 0rem;
        bottom:-1rem;
    }
}