.join{
    padding: 0 2rem;
    gap: 10rem;
    display: flex;
}
.left-j{
    color: white;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
}
.left-j>hr{
    width: 10.5rem;
    position: absolute;
    border: 2px solid #f48915;
    border-radius: 20%;
    margin: -10px 0;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.emailContain{
    display: flex;
    background-color: #9c9c9c;
    padding: 1rem 2rem;
    gap: 3rem;
}
.emailContain >input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #D9D9D9;
}
::placeholder{
    color: #D9D9D9;
}
.btn-j{
    background-color: #f48915;
    color: white;
}
@media screen and (max-width:768px){
    .join{
        flex-direction: column;
        grid-template: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: -4rem 0;
        position: relative;
        top: -8rem;
        width: 90%;
        height: 90%;
        align-self: center;
        
    }
    ::placeholder,.btn-j{
        font-size: 90%;
        
    }
    form{
        height: 26px;
        width: 98%;
    }
    .btn-j{
        position: relative;
        align-self: center;
    }
    input{
        width:150px;
        position: relative;
        /* right:-1rem; */
    }
    
    
}