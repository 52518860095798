.reason{
    margin: 0 2rem;
    display: flex;
    grid-template: 2rem;
    
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r img{
    object-fit: cover;
}
.left-r :nth-child(1){
    width: 12rem;
    height: 28rem;
    grid-row: 1/3;
}
.left-r :nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-r :nth-child(3){
    width: 14rem;
    grid-column: 2/3;
}
.left-r :nth-child(4){
    width: 10rem;
    grid-column: 3/4;
    height: 11.2rem;
}
.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight: bold;
    color: #f48915;
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    grid-template: 1rem;
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-r div img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners img{
    width: 2.5rem;
}

@media screen and (max-width:768px){
    .reason{
        flex-direction: column;
    }
    .left-r{
        overflow: hidden;
    }
    .left-r>:nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r>:nth-child(2){
        width: 10rem;
        height: 10rem;
    }
    .left-r>:nth-child(3){
        width: 8rem;
        height: 6rem;
    }
    .left-r>:nth-child(4){
        width: 7rem;
        height: 0rem;
    }
    .reasonsWhy>span{
        font-size: x-large;
        gap: 0;
    }
    .reasonsWhy{
        margin-top: -2rem;
    }
    .reasons1{
        gap: 0;
    }
    .details-r>div>span{
        font-size: 80%;
        
    }
    .details-r>div>img{
        width: 5%;
        height: 5%;
    }
    .details-r>div{
        gap: 1%;
    }
}

