.footerContainer{
    position: relative;
}
.footerContainer>hr{
    border:1px solid #D9D9D9;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 15rem;
}
.social{
    display: flex;
    gap: 4rem;
}
.social>img{
    height: 2rem;
    width: 2rem;
}
.logo-f>img{
    width: 10rem;
    justify-content: center;
    align-items: center;  
}
.blur-f1{
    right: 15%;
    bottom: 0;
    height: 12rem;
    width: 26rem;
    filter: blur(200px);
    background: red;
}
.blur-f2{
    left: 15%;
    bottom: 0;
    height: 12rem;
    width: 26rem;
    filter: blur(200px);
    background: rgb(255, 115,0);
}

@media screen and (max-width:768px) {
    .footerContainer{
        position: relative;
        top: -8rem;
    }
}