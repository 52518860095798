.pragramHeader{
    text-align: center;
    font-size: 3.2rem;
    font-style: italic;
    font-weight: bold;
}
.pragramHeader span{
    padding: 0 0rem;
}
.planContainer{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan {
    display: flex;
    flex-direction: column;
    background: #656565;
    color: white;
    gap: 3rem;padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    transform: scale(1.1);
}
.plan>svg{
    fill: #f48915;
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    align-items: center;
    gap:1rem;
    display: flex;
}
.feature img{
    width: 1rem;
    height: 1rem;
}
.plan:nth-child(2)>svg{
    fill: white;
}
.seeMore{
    margin-top: -1rem;
    align-items: center;
    justify-content: center;
}
.seeMore>button{
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
}
.plan:nth-child(2) button{
    color: #f48915;
}
.blur-p1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.blur-p2{
    width: 32rem;
    height: 23rem;
    top: 15rem;
    right: -5rem; 
}
@media screen and (max-width:768px){
    .planContainer{
        flex-direction: column;
        font-size: small;
        gap: 0rem;
        justify-content: center;
        align-items: center;
    } 
    .pragramHeader>:nth-child(3){
        font-size: 60%;
        
    }
    .pragramHeader>:nth-child(1){
        font-size: 55%;
    }
    .pragramHeader>:nth-child(2){
        font-size: 60%;
    }
    .plans{
        flex-direction: column;
    }
    .plan:nth-child(2){
        transform: none;
    }

}