.programs{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 0 2rem;
}
.program-header{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.program-catagories{
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .catagory{
    display: flex;
    flex-direction: column;
    background-color: #9c9c9c;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
  }
  .catagory :nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
  }
  .catagory :nth-child(2){
    font-size: 1rem;
    font-weight: bold;
  }
  .catagory :nth-child(3){
    font-size: 0.9em;
    line-height: 25px;
  }
  .joinNow{
    align-items: center;
    gap: 2rem;
  }
  .joinNow img{
    padding-left: 1rem;
    width: 1rem;
  }
  .catagory:hover{
    background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
    cursor: pointer;
  }
  @media screen and (max-width:768px) {
    .program-header{
      flex-direction: column;
      gap: 0;
      font-size: xx-large;
      align-items: center;
      justify-content: center;
      margin-top: -2rem;
    }
    .program-catagories{
      flex-direction: column;
    }
  }
  